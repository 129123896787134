.logo {
  fill: currentColor;
  &.-nav {
    width: 7.75em;
    height: 2em;
  }
  &.-side {
    display: none;
    @include responsive(medium) {
      display: block;
      position: fixed;
      width: 18.4em;
      height: 35.2em;
      right: 0.5em;
      bottom: 1.5em;
    }
    @include responsive(large) {
      width: 23em;
      height: 44em;
    }
  }
}
