.radio-player {
  display: flex;
  align-items: center;
  @include responsive-to(medium) {
    flex-wrap: wrap;
  }
  @include responsive-to(small) {
    justify-content: center;
  }
  .info {
    flex: 1;
    h3 {
      margin: 0;
    }
    p {
      margin: 0;
    }
  }
}
