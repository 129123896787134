.view-container {
  padding: 6em 1em 6em;
  @include responsive-to(small) {
    padding-top: 7.5em;
  }
  max-width: 40em;
  margin: auto;
  &.-start {
    @include responsive-to(small) {
      h1 {
        display: none;
      }
      h2, h3 {
        text-align: center;
      }
    }
    .play-button {
      @extend %play-button-huge;
      margin-right: 2em;
      @include responsive-to(medium) {
        margin-top: 3em;
      }
      @include responsive-to(small) {
        margin-right: 0;
        margin-top: 1.5em;
      }
    }
  }
  &.-programs {
    nav {
      list-style: none;
      a {
        &:hover {
          text-decoration: none;
        }
        &.active {
          color: c(alt);
          text-decoration: none;
        }
      }
      //@include responsive(small) {
        display: flex;
        li {
          &::after {
            // en-space bullet en-space
            content: '\2002\2022\2002';
          }
          &:last-child::after {
            content: none;
          }
        }
      //}
      @include responsive-to(small) {
        font-size: 0.85em;
      }
    }

	h2{
		@include responsive-to(small){
			font-size:1.4rem;
		}
	}
  }
  a.program-link {
    text-decoration: none;
  }
  .licenses {
    li {
      font-size: 0.75rem;
    }
  }
  .player-area {
    margin-top: 1em;
  }
  @include responsive(medium) {
    padding: 5em 20em 6em 1em;
  }
  @include responsive(large) {
    padding: 5em 24em 6em 1em;
  }
  @include responsive(xlarge) {
    padding: 5em 20em 6em 1em;
  }
}

.timezone-info
{
	font-size: 0.8em;
}