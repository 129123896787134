.play-button {
  @include transition('background');
  border-radius: 50%;
  border: none;
  cursor: pointer;
  padding: 0.25em;
  color: c(brand-alt);
  background: c(brand);
  &:hover {
    background: c(brand, 0.1);
  }
  .icon {
    width: 2.5em;
    height: 2.5em;
  }

  &%play-button-huge {
    align-self: flex-start;
    margin-top: 2em;
    .icon {
      width: 12em;
      height: 12em;
    }
  }

  &%play-button-inverted {
    color: c(brand);
    background: c(brand-alt);
    &:hover {
      background: c(brand-alt, 0.1);
    }
  }
}

.play-button.stopped {
  .stop {
    display: none;
  }
  .play {
    display: block;
  }
}
.play-button.playing {
  .stop {
    display: block;
  }
  .play {
    display: none;
  }
}
