@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,800');

$gilroy: '../files/gilroy-extrabold';
@font-face {
  font-family: 'Gilroy';
  src: url('#{$gilroy}.woff2') format('woff2'),
       url('#{$gilroy}.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

html {
  min-height: 100%;
}

body {
  @include t(body);
  color: c(alt);
  font-size: 100%;
  min-height: 100%;
  background: linear-gradient(0deg, c(bg-end) 0%, c(bg-start) 100%), url('../files/bg.svg') center center / cover no-repeat fixed;
}

h1 {
  @include t((caps, level1s));
}

h2 {
  @include t((caps, level2s));
}

h3 {
  @include t((caps, level3s));
}

@include responsive(small) {
  h1 {
    @include t((caps, level1m));
  }
  h2 {
    @include t((caps, level2m));
  }
  h3 {
    @include t((caps, level3m));
  }
}

@include responsive(medium) {
  h1 {
    @include t((caps, level1));
  }
  h2 {
    @include t((caps, level2));
  }
  h3 {
    @include t((caps, level3));
  }
}

strong {
  font-weight: 600;
}
