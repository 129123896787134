$brand: 'default' !default;

$colors: (
    brand: #660f11,
    brand-alt: #FFD42A,
    base: #FFFBFC,
    alt: #660f11,
    links: #660f11,
    bg-start: #c21b1d79,
    bg-end: #ffd42ada,
) !default;

@if $brand == 'aprilli' {
  $colors: (
    brand: #FF5A88,
    brand-alt: #C9F2FF,
    base: #C9F88D,
    alt: #039AF5
  );
}

$typography: (
  body: (
    font-weight: 400,
    line-height: 1.25,
    font-family: ('Open Sans', sans-serif)
  ),
  caps: (
    text-transform: uppercase,
    font-weight: 800,
    letter-spacing: 0.05em,
    font-family: ('Gilroy', 'Open Sans', sans-serif)
  ),
  level1: (
    font-size: 4rem,
    line-height: 1,
    margin: 2rem 0 1rem
  ),
  level2: (
    font-size: 2rem,
    line-height: 1,
    margin: 1.5rem 0 1rem
  ),
  level3: (
    font-size: 1.5rem,
    line-height: 1,
    margin: 1.5rem 0 1rem
  ),
  level4: (
    font-size: 0.75rem,
    line-height: 0.75rem,
    margin: 1rem 0
  ),
  level1s: (
    font-size: 2.25rem,
    line-height: 1,
    margin: 1rem 0 0.5rem
  ),
  level2s: (
    font-size: 1.75rem,
    line-height: 1,
    margin: 1rem 0 0.5rem
  ),
  level3s: (
    font-size: 1.25rem,
    line-height: 1,
    margin: 1rem 0 0.5rem
  ),
  level1m: (
    font-size: 2.25rem,
    line-height: 1,
    margin: 1rem 0 0.5rem
  ),
  level2m: (
    font-size: 1.75rem,
    line-height: 1,
    margin: 1rem 0 0.5rem
  ),
  level3m: (
    font-size: 1.25rem,
    line-height: 1,
    margin: 1rem 0 0.5rem
  ),
  small: (
    font-size: 0.75rem,
    line-height: 1rem
  ),
  paragraph: (
    margin: 1rem 0
  )
);

$breakpoints: (
  /** Generic breakpoints for whole site outlook */
  small: 52rem,
  medium: 60rem,
  large: 68rem,
  xlarge: 72rem,
  /** Breakpoints for top-bar */
  // extraSmallTop: 32rem,
  // smallTop: 56rem,
  // mediumTop: 72rem,
  // largeTop: 80rem,
  // Above are values that would look good without audio bar.
  extraSmallTop: 42rem,
  small2LinesTop: 44rem,
  smallTop: 76rem,
  mediumTop: 92rem,
  largeTop: 92rem,
  /** Breakpoints for when audio is on */
  hideAudioTop: 42rem,
  extraSmallTopAudio: 32rem,
  smallTopAudio: 76rem,
  mediumTopAudio: 92rem,
  largeTopAudio: 92rem,
);

@function get-from-map($map, $name) {
  @if map-has-key($map, $name) {
    @return map-get($map, $name);
  }
  @else {
    @warn "No value for `#{$name}`";
  }
}

@function c($name, $transparency: 0) {
  @return transparentize(get-from-map($colors, $name), $transparency);
}

@mixin transition($properties, $duration: 0.2) {
  $list: ();

  @each $property in $properties {
    $list: append($list, #{$property} #{$duration}s ease-in-out, comma);
  }

  transition: $list;
}

@mixin t($names) {
  @each $name in $names {
    @each $property, $value in get-from-map($typography, $name) {
      #{$property}: $value;
    }
  }
}

@mixin responsive($breakpoint, $type: min) {
  @media only screen and (#{$type}-width: get-from-map($breakpoints, $breakpoint)) {
    @content;
  }
}

@mixin responsive-to($breakpoint) {
  @include responsive($breakpoint, max) {
    @content;
  }
}
