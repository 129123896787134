$bottom-bar-border: 1px solid c(base, 0.8);

.bottom-bar {
  @extend %inverted;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  border-top: $bottom-bar-border;
  z-index: 1;
  :focus {
    outline-color: c(brand-alt);
  }
  a {
    flex: 1 1;
    max-width: 20em;
    text-decoration: none!important;
    color: c(base)!important;
	@include responsive-to(small) {
	  display: none;
	}
  }
  .info-item {
    @extend %info-item-tiny;
    border-right: $bottom-bar-border;
    flex: 1;
    max-width: 20em;
    text-align: center;
    &:first-child {
      text-align: left;
	  @include responsive-to(small) {
		display: none;
	  }
    }
    &:last-child {
      border: none;
      text-align: right;
      flex-direction: row-reverse;
    }
	@include responsive-to(small){
		  border-right: none;
	}
  }
  .play-button {
    @extend %play-button-inverted;
  }
  .current > img {
    width: 64px;
    height: 64px;
    margin: -15px auto;
    border-radius: 50%;
  }
  
}
