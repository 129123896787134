.layout_container {
  position:fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0,0,0,0.5);
}
.feedback_modal {
  position: absolute;
  background-color: c(alt);
  color: c(base); 
  top: 50%;
  left: 50%;
  height: 300px;
  width: 300px;
  transform: translate(-50%, -50%);

  padding: 0.5em;
  margin: 0.5em 0;
  text-align: center;
}
.feedback_rating [type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}
.feedback_rating label {
    display: inline-block;
    background-color: c(alt);
    color: c(base);
    padding: 10px 20px;
    font-family: sans-serif, Arial;
    font-size: 16px;
    border: 2px solid #444;
    border-radius: 4px;
}
.feedback_rating input[type="radio"]:checked + label {
    border: 2px solid #444;
    background-color:c(brand);
    border-color: c(brand-alt);
}
.feedback_rating input[type="radio"]:focus + label {
    border: 2px solid #444;
    background-color:c(brand);
    border-color: c(brand-alt);
}

.feedback_modal input[type="text"] {
    width: 85%;
}

.feedback_modal input[type="submit"] {
    background-color: c(brand);
    color: #FAFAFA;
}
.closeModal {
    float:right;
    background-color: c(brand);
    color: #FAFAFA;
    border:1px 1px 1px 1px;
}
.modalTitle {
    display:inline;
}
