.info-item-container {
  margin-top: 2em;
  >.previousNextLink {
	  margin-top:0.75em;
    font-weight: bold;
    font-size: 1.25em;
    text-decoration: none;
  }
  >.previousLink {
    float:left;
    text-align:left;
  }
  >.nextLink {
    float:right;
    text-align:right;
    @include responsive(medium) {
      float: left;
      margin-left: 4px;
    }
  }
  @include responsive(medium) {
    .nextLink::before{
      content: "█";
      display: inline-block;
      margin-right: 4px;
    }
  }
}

.info-item {
  display: flex;
  align-items: center;
  padding: 0.5em;
  >.content {
    flex: 1;
    max-width: 100%;
    >h2 {
      @include t((caps, level2));
    }
    >h2, >p {
      margin: 0;
    }
    >.main {
      font-weight: 600;
    }
    >.desc {
      >p {
        margin: 0;
        margin-top: 0.75em;
      }
    }
  }
  >.feedbackLink {
    margin-top: 0.75em;
    width: 70%;
    float:left;
    text-align: center;
  }
  >.content, >.action, >.img {
    margin: 0.5em;
  }


  &%info-item-tiny {
    >.content {
      >h2 {
        @include t(level4);
      }
      >.main {
        padding: 0.125em 0;
        font-weight: 400;
      }
      >.sub {
        @include t(small);
      }
      >h2, >p {
        margin: 0;
      }


    }
  }
  &.-program {
    @extend %inverted;
    padding: 0.5em;
    margin: 0.5em 0;
    border-radius: 0.125em;
    >.img {
      width: 5.5em;
      height: 5.5em;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
    >.content {
      >.main {
        font-weight: inherit;
      }
      >.dates {
        @include t((caps, level4));
        margin: 0.5em 0 0.375rem;
        >.date {
          display: inline-block;
          color: c(alt);
          background:c(base);
          padding: 0.125em 0.5em 0;
          border-radius: 1em;
          margin: 0 0.5em 0.5em 0;
          text-decoration: none;
        }
		>.activeDate
	    {
		  background:c(brand-alt);
	    }
      }
    }
    &.-many {
      &:hover {
        >.content {
          >.main {
            color: c(brand-alt, 0.2);
            text-decoration: none;
          }
        }
      }
      >.content {
        >.main {
          @include transition(color);
          color: c(brand-alt);
          text-decoration: underline;
        }
        >.sub {
          >span {
            display: block;
            >.label {
              display: inline-block;
              min-width: 4.4em;
              color: c(base, 0.5);
            }
            @include responsive-to(small) {
              .label {
                display: none;
              }
              display: inline;
              &:nth-child(2):before {
                content: " + ";
                color: c(base, 0.5);
              }
            }
          }
        }
        >.desc {
          @supports not (-webkit-line-clamp: 3) {
            &::after {
              content: "";
              width: 20%;
              height: 1.2em;
              background: linear-gradient(to right, c(alt, 0.75), c(alt, 0));
              position: absolute;
              right: 0;
              bottom: 0;
            };
          }
          margin-top: 0.5em;
          position: relative;
          max-height: 3.8em;
          overflow: hidden;
          word-break: break-word;
          text-overflow: ellipsis;
          display: -webkit-box;
          /* autoprefixer: ignore next */
          -webkit-box-orient: vertical;
          /* ...idiot */
          -webkit-line-clamp: 3;
        }
      }
    }
    &.-single {
      clear: both;
      position: relative;
      margin-top: 0em;
      flex-direction: column;
      hyphens: manual;
      >.img {
        margin: 0 0 0.5em;
        border-radius: 0.5em;
        width: 100%;
        max-width: 30em;
        height: auto;
      }
      >.content {
        >.sub {
          >span {
            display: block;
            >.label {
              display: inline-block;
              min-width: 4.4em;
              color: c(base, 0.5);
            }
          }
        }
        >.desc {
          margin-top: 1em;
          white-space: pre-wrap;
          word-break: break-word;
        }
      }
      @include responsive(small) {
        >.img {
          margin: 1em 0.5em 0.5em;
        }
      }
      @include responsive-to(small) {
        hyphens: manual;
      }
      @include responsive(medium) {
        flex-direction: row;
        flex-direction: row-reverse;
        margin-right: -19em;
        //margin-top: 8em;
        >.img {
          margin: -6em 0.5em;
        }
      }
      @include responsive(large) {
        margin-right: -23em;
        >.img {
          width: 30em;
          margin-left: 1em;
        }
      }
    }
  }
}

.timetable {
  .info-item {
    align-items: flex-start;
    @include responsive-to(small) {
      .desc {
        margin-left: -6.5em;
      }
      .sub {
        min-height: 2em;
      }
    }
  }
}

// @include responsive(medium) {
//   padding: 5em 20em 6em 1em;
// }
// @include responsive(large) {
//   padding: 5em 24em 6em 1em;
// }
// @include responsive(xlarge) {
//   padding: 5em 20em 6em 1em;
// }
