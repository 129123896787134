a, .back-button {
  @include transition(color);
  color: c(links);
  text-decoration: underline;
  &:hover {
    color: c(brand, 0.2);
    text-decoration: none;
  }
}

.back-button {
  @include t((caps, level3));
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &::before {
    content: '\2039\00a0';
  }
}

%inverted {
  color: c(base);
  background: c(alt);
  a {
    color: c(brand-alt);
    &:hover {
      color: c(brand-alt, 0.2);
    }
  }
}

:focus {
  outline: 2px dotted c(brand);
}
